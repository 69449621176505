$(document).ready(function () {

    'use strict';


    /***** helper funtions *****/


    function initMainNavigation() {
        // umsortieren der subnavigation
        //$('#main-navigation a').next('.row').appendTo('.sub-navigation-wrapper .container.narrow');

        $('.burger-wrap').click(function () {
            $(this).toggleClass('open');
            $(this).parent().toggleClass('open');
            $('body, html').toggleClass('panel-open');
        });

        $('.nav-sub-item.has-sub').click(function (e) {
           $(this).toggleClass('open');
           e.preventDefault();
        });


        $('#main-navigation a.nav-main-item').click(function (event) {
            var $item = $(this);
            if ($item.hasClass('open')) {
                $item.removeClass('open');
            } else {
                $('#main-navigation a').removeClass('open');
                $item.addClass('open');
            }
            var wrapperHeight = $('.slider-section').height();
            event.preventDefault();
            $('#main-navigation a.nav-main-item').removeClass('current');
            $item.addClass('current');
            $('#main-navigation').addClass('open');

            $('.sub-navigation-wrapper').addClass('open');
            $('.sub-navigation').removeClass('active');
            $('#' + $item.data('target')).addClass('active');

        });

        $('.navigation-close').click(function () {
            $('.sub-navigation-wrapper').removeClass('open');
            $('#main-navigation').removeClass('open');
            $('#main-navigation a.nav-main-item').removeClass('current');
            $('.burger-wrap').removeClass('open');
        });

    }

    function alignEvents() {
        $('.section-content .event,.section-content .content').each(function () {
            var $that = $(this);
            $that.css({
                marginTop: 0 - ($that.height() / 2),
            })
        });
    }

    function initCalendar($calendar) {
        $calendar.find('.next, .prev').click(function () {
            $.post('/?type=8384&month=' + $(this).data('month') + '&year=' + $(this).data('year'), {
            }, function (data) {
                var $newCalendar = $(data);
                $calendar.replaceWith($newCalendar);
                initCalendar($newCalendar);
            });
        });
    }


    function initStoerer() {

        // align stoerer
        $('.stoerer-wrapper .stoerer').each(function () {
            var stoererUid = $(this).data('stoerer');
            console.log($.cookie('stoerer' + stoererUid))
            if ($.cookie('stoerer' + stoererUid) != 'closed') {
                $(this).css({display: 'block'});
            }
        });

        $('.stoerer-wrapper').addClass('show');


        $('.stoerer .close-button').click(function (event) {
            event.preventDefault();
            var stoererUid = $(this).data('stoerer');
            $.cookie('stoerer' + stoererUid, 'closed', {expires: 7});
            $('.stoerer' + stoererUid).fadeOut();
        });
    }

    function initLightbox() {
        $('.image-link').magnificPopup({
            type: 'image'
        });
    }


    function initTicketLinks() {
        $('' +
            'a[href*="//tickets.vibus.de"], ' +
            'a[href*="//www.delta-konzerte.de"], ' +
            'a[href*="//www.rockbuster.de"], ' +
            'a[href*="//www.ruthhutter.de"], ' +
            'a[href*="//www.staatsphilharmonie.de"], ' +
            'a[href*="eventim-inhouse.de"], ' +
            'a[href*="//secure.adticket.de"], ' +
            'a[href*="reservix.de"], ' +
            'a[href*="//www.eventbrite.de"], ' +
            'a[href*="//www.viersaitenagentur.de"] ' +
            '').click(function (e) {
            e.preventDefault();
            if (confirm('Sie werden auf der nächsten Seite zu unserem Servicepartner weitergeleitet. Bitte beachten Sie, dass die Stadt Ludwigshafen am Rhein für die Erhebung und Verarbeitung personenbezogener Daten innerhalb des dort angebotenen Dienstes nicht verantwortlich ist und eigene Datenschutzbedingungen des Anbieters gelten.') === true) {
                window.open($(this).attr('href'), '_blank');
            }
        });
    }

    function initGotoLinks() {
      $(".goto").click(function() {
        $([document.documentElement, document.body]).animate({
          scrollTop: $($(this).attr('href')).offset().top
        });
      });
    }

    /******** init section **********/


    $('.my-slider').unslider({
        autoplay: true,
        infinite: true,
        animateHeight: true,
        // animation: 'vertical',
        nav: false
    });


    initStoerer();


    $.apScrollTop();
    $('.spielplan .cols-50').matchHeight();

    initMainNavigation();
    $('.calendar-wrapper').each(function () {
        initCalendar($(this));
    });
    alignEvents();

    initLightbox();

    var userAgent = window.navigator.userAgent;
    if (/iP(hone|od|ad)/.test(userAgent)) {
        $('.home-section').css({
            backgroundAttachment: 'scroll'
        });
    }

    if ($('section.detailview').length > 0) {
        $('html,body').stop().animate({scrollTop: 100}, '2500', 'swing');
    }

    initTicketLinks();
    initGotoLinks();

});
